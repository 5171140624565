import React, { useRef, useEffect } from "react"

import * as d3 from "d3"
import "./style.css"
import fondCarto from "./dep2.json"
import data from "../dataset/data.json"
import territoires from "../dataset/territoires.json"
import _ from "lodash"

// sources : https://medium.com/@jeffbutsch/using-d3-in-react-with-hooks-4a6c61f1d102
// https://www.freecodecamp.org/news/how-to-get-started-with-d3-and-react-c7da74a5bd9f/

// https://www.datavis.fr/index.php?page=map-population

export default ({ palettes, intervalles }) => {
  const d3Container = useRef(null)
  const d3Tooltip = useRef(null)

  useEffect(() => {
    if (d3Container && d3Container.current && d3Tooltip && d3Tooltip.current) {
      const width = 400,
        height = width

      const path = d3.geoPath()

      const projection = d3
        .geoConicConformal() // Lambert-93
        .center([2.454071, 46.279229]) // Center on France
        .scale((2600 * width) / 500.0)
        .translate([width / 2, height / 2])

      path.projection(projection)

      const svg = d3
        .select(d3Container.current)
        .append("svg")
        .attr("id", "svg")

        .attr("width", width)
        .attr("height", height)

      const deps = svg.append("g")
      console.log(path)

      deps
        .selectAll("path")
        .data(fondCarto.features)
        .enter()
        .append("path")
        .attr("id", d => "d" + d.properties.code)
        .attr("class", "departement")
        .attr("d", path)

      let dataMin = d3.min(data, d => d.taux)
      let dataMax = d3.max(data, d => d.taux)

      let quantile = d3.scaleLinear().domain([dataMin, dataMax]).range([0, 1])

      var div = d3.select(d3Tooltip.current).attr("class", "tooltip")

      data.forEach(datum => {
        d3.select("#d" + datum.codzone)
          .attr("stroke", "white")
          .attr("fill", geom => {
            const valeurs = _.find(
              data,
              item => geom.properties.code === item.codzone
            )
            if (!valeurs && !valeurs.taux) {
              return "rgb(200,200,200)"
            }
            // on recherche son intervalle
            let intervalle = 0
            while (
              intervalle < intervalles.length &&
              intervalles[intervalle] <= valeurs.taux
            ) {
              intervalle++
            }
            return palettes[intervalle]
          })
          .on("mouseover", function (d) {
            const territoire = _.find(
              territoires,
              t => t.codzone === d.properties.code
            )
            if (!territoire) {
              return
            }
            const valeurs = _.find(data, d => d.codzone === territoire.codzone)
            if (!valeurs) {
              return
            }
            div.transition().duration(200).style("opacity", 0.9)
            div
              .html(`${territoire.nom}<br/>Taux fragilité : ${Math.floor(valeurs.taux*10)/10} %`)
              .style("left", d3.event.pageX + 20 + "px")
              .style("top", d3.event.pageY - 20 + "px")
          })
          .on("mouseout", function (d) {
            div.style("opacity", 0)
            div.html("").style("left", "-500px").style("top", "-500px")
          })
      })

      let legend = svg
        .append("g")
        .attr("transform", "translate(525, 150)")
        .attr("id", "legend")

      let scaleLegend = d3
        .scaleLinear()
        .domain([0, 15])
        .range([dataMin, dataMax])
      legend
        .selectAll(".colorbar")
        .data(d3.range(15))
        .enter()
        .append("svg:rect")
        .attr("y", d => 15 * 5 - d * 5 + "px")
        .attr("height", "5px")
        .attr("width", "10px")
        .attr("x", "0px")
        .attr("fill", d => d3.interpolatePuOr(quantile(scaleLegend(d))))
    }
  }, [d3Container])

  return (
    <>
      <div ref={d3Tooltip}></div>
      <div ref={d3Container}></div>
    </>
  )
}

import React from "react"

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  ModalCloseButton,
  useDisclosure,
  Text,
  Stack,
  List,
  ListItem,
} from "@chakra-ui/core"

function DisplayModal() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button onClick={onOpen} size="sm">
        Comprendre les valeurs du graphique
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Comprendre les valeurs du graphique</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={5} fontSize="sm">
              <Text>
                Le baromètre de fragilité  des personnes âgées de 75 ans et plus s'appuie sur 7
                indicateurs distincts calculés sur chacun des territoires observés. Ainsi, nous
                calculons la part des personnes âgées répondant aux critères
                suivants :
              </Text>
              <List styleType="disc">
                <ListItem>
                  vivant dans un logement ancien de 4 pièces ou plus,
                </ListItem>
                <ListItem>
                  vivant dans un logement collectif sans ascenseur,
                </ListItem>
                <ListItem>
                  {" "}
                  vivant dans un logement social, précaire ou locataires dans
                  collectif privé ancien,
                </ListItem>
                <ListItem>
                  vivant seule dans son logement{" "}
                  <Text as="em">(critère comptant pour 2 points)</Text>,
                </ListItem>
                <ListItem>
                  vivant dans un ménage non équipé d'une voiture,
                </ListItem>
                <ListItem>
                  dont la personne référente du ménage (PRM) possède un bas
                  niveau de formation,
                </ListItem>
                <ListItem>
                  dont la personne référente du ménage (PRM) est immigrée.
                </ListItem>
              </List>
              <Text>
                La grande majorité des personnes âgées rencontrent l’un ou
                l’autre de ces critères. La fragilité n’apparaît donc pas
                lorsqu’un seul facteur est rempli, mais sitôt qu’ils se
                cumulent. Ainsi, dès qu’une personne âgée{" "}
                <Text as="em">cumule</Text> 5 points de ces 8 points possibles,
                elle peut être considérée comme fragile .
              </Text>
              <Text>
                Les 7 axes du graphique représentent ces 7 indicateurs, <Text as="strong">chacun
                rapporté à la moyenne métropolitaine (valeur 100)</Text> : <Text as="em">une valeur de 150 signifie que l'indicateur est 1,5 plus élevé que la moyenne métropolitaine.</Text>.
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variantColor="blue" mr={3} onClick={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
export default DisplayModal

import React, { useState, useRef } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/fragilites-des-personnes-agees/home"
import SelectTerr from "../components/fragilites-des-personnes-agees/select"
import AppCore from "../components/fragilites-des-personnes-agees/appCore"
import imgsante from "../images/revenus-pa.svg"
const ComparateurPA = () => {
  const [codzone, setCodzone] = useState(undefined)
  const selectContainer = useRef(null)
  const headerContainer = useRef(null)

  return (
    <Layout
      title="L'indice de fragilité des personnes âgées"
      headerContainer={headerContainer}
      img={imgsante}
    >
      <SEO title="Baromètre Compas - L'indice de fragilité des personnes âgées" />
      <Home
        codzone={codzone}
        setCodzone={setCodzone}
        selectContainer={selectContainer}
      />
      <SelectTerr
        codzone={codzone}
        setCodzone={setCodzone}
        selectContainer={selectContainer}
        headerContainer={headerContainer}
      />
      <AppCore
        codzone={codzone}
        setCodzone={setCodzone}
        selectContainer={selectContainer}
        headerContainer={headerContainer}
      />
    </Layout>
  )
}

export default ComparateurPA

import React, { useState, useEffect } from "react"

import { Flex, Text, Select, Link } from "@chakra-ui/core"
import { Box } from "@chakra-ui/core"
import { FaMap } from "react-icons/fa"
import _ from "lodash"

import ModalCriteres from "./ModalCriteres"
import ModalMethodo from "./ModalMethodo"
import Carte from "./carte"
import Radar from "./radar"
import data from "./dataset/data.json"
import territoires from "./dataset/territoires.json"
import Position from "./position"
import startImg from "../../images/37255.jpg"

export function preparerDatasetPA(currentCodzone) {
  const palettes = [
    "rgb(4,102,134)",
    "rgb(0,156,180)",
    "rgb(243,219,173)",
    "rgb(225,164,51)",
  ]
  const intervalles = [14.7, 17.3, 20.1]

  const labels = {
    // d1: "% dans logt. ancien de 4 pièces ou +",
    // d2: "% dans logt. collectif sans ascenseur",
    // d3: "% dans logt social ou locataire",
    // d4: "% PRM de bas niveaux de formation",
    // d5: "% personne vivant seule",
    // d6: "% dans un ménage sans voiture",
    // d7: "% dont PRM est immigrée",
    d1: "Logt. ancien de 4 pièces ou +",
    d2: "Logt. collectif sans ascenseur",
    d3: "Logt social ou locataire",
    d4: "PRM de bas niveaux de formation",
    d5: "Personne vivant seule",
    d6: "Ménage sans voiture",
    d7: "PRM est immigrée",
  }

  const dataFM = {
    codzone: "France métropolitaine",
    taux: 17.2816505548088,
    d1: 60.8675590780172,
    d2: 12.3981411047048,
    d3: 17.251198373351,
    d4: 61.0309774680138,
    d5: 42.6297345298093,
    d6: 30.3516854073726,
    d7: 7.69986974394726,
  }

  const datum = _.find(data, item => item.codzone === currentCodzone)
  const territoire = _.find(
    territoires,
    terr => terr.codzone === currentCodzone
  )
  let dataForRadar = []
  let maxValueForRadar = undefined
  let zoneColor = "#FF00FF"

  if (datum) {
    dataForRadar = _.keys(datum)
      .filter(key => key !== "codzone" && key !== "taux")
      .map(key => {
        const valDep = Math.floor((datum[key] / dataFM[key]) * 1000) / 10

        if (valDep > maxValueForRadar || !maxValueForRadar) {
          maxValueForRadar = valDep
        }
        if (dataFM[key] > maxValueForRadar || !maxValueForRadar) {
          maxValueForRadar = dataFM[key]
        }
        return {
          critere: `${labels[key]}`,
          [`${territoire.nom}`]: valDep,
          "France Métropolitaine":
            Math.floor((dataFM[key] / dataFM[key]) * 1000) / 10,
        }
      })
    let intervalle = 0
    while (
      intervalle < intervalles.length &&
      intervalles[intervalle] <= datum.taux
    ) {
      intervalle++
    }
    zoneColor = palettes[intervalle]
  }
  return {
    territoire,
    datum,
    dataForRadar,
    zoneColor,
    palettes,
    maxValueForRadar,
    intervalles,
    labels,
  }
}

function AppCore({ codzone: currentCodzone, setCodzone, selectContainer }) {
  const {
    territoire,
    datum,
    dataForRadar,
    zoneColor,
    palettes,
    intervalles,
    maxValueForRadar,
    labels,
  } = preparerDatasetPA(currentCodzone)
  return (
    <>
      <Box ml={10} mr={10} ref={selectContainer}>
        {currentCodzone && territoire ? (
          <>
            <Text fontSize="lg" fontWeight="600" mt={5}>
              Part des personnes âgées fragiles ={" "}
              {Math.floor(datum.taux * 10) / 10} %
            </Text>
            <Text fontSize="md">Référence nationale = 17.3</Text>

            <Flex direction="row" wrap="wrap">
              <Flex minWidth="600px" height="400px">
                <Radar
                  data={dataForRadar}
                  keys={[territoire.nom, "France Métropolitaine"]}
                  labels={labels}
                  zoneColor={zoneColor}
                  maxValue={maxValueForRadar * 1.1}
                />
              </Flex>

              <Flex direction="column">
                <Text>En comparaison avec les autres départements</Text>
                <Carte palettes={palettes} intervalles={intervalles} />
              </Flex>
            </Flex>
            <Flex justifyContent="space-between">
              <Flex>
                <Box mr={5}>
                  <ModalMethodo />
                </Box>
                <ModalCriteres />
              </Flex>
              <Flex>
                <Text fontSize="xs">
                  Sources :{" "}
                  <Link href="http://www.le-compas.fr" isExternal>
                    Traitements Compas©
                  </Link>
                  , Insee RP 2016
                </Text>
              </Flex>
              <Flex>
                <Position
                  palettes={palettes}
                  intervalles={intervalles}
                  taux={datum.taux}
                />
              </Flex>
            </Flex>
          </>
        ) : null}
      </Box>
    </>
  )
}

export default AppCore

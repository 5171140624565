import React from "react"
import { Flex, Text, Box, Stack, Icon, Link } from "@chakra-ui/core"

import image from "../../images/43122.jpg"
import startImg from "../../images/image_CompaszOOm25-22Avril2020.png"
export default function Home() {
  return (
    <>
      <Flex alignItems="center" mr={10} ml={10}>
        <Flex flex={10} direction="column">          
          <Stack spacing={5} >
            <Text fontSize="lg" fontWeight="600">
              Veille sociale : Isolement des personnes âgées
            </Text>
            <Text>
              La crise sanitaire a mis en avant les fragilités des seniors face
              à la problématique de santé. A l’identique de celle de 2003 avec
              la canicule, celle de 2020 renforce encore plus le besoin d’une
              action sociale de proximité fortement orientée vers
              l’accompagnement et la prise en charge de cette population
              fragile.
            </Text>

            <Text>
              Il est donc essentiel de pouvoir  mettre en &#x153;uvre des outils
              qui permettent à la fois de quantifier les besoins mais aussi de
              les qualifier. </Text>
              
              <Text>Le{" "}
              <Link href="http://www.lecompas.fr/" isExternal>
                Compas
                <Icon name="external-link" mx="2px" />
              </Link>{" "}
              a retranscrit ces enjeux en un{" "}
              <Text as="span" style={{ fontWeight: "600" }}>
                baromètre©Compas{" "}
              </Text>
              : il est dès lors possible de définir les
              fragilités des seniors (d’aujourd’hui et de demain), qu’elles
              soient liées à la mobilité  ou au logement ou bien encore aux
              questions d’économie et d’accès aux droits.
            </Text>

  
          </Stack>
        </Flex>
        <Flex flex={6} direction="column">
          <Flex alignItems="center" m={0}>
            <img
              src={image}
              width={"50%"}
              alt="lecture"
              style={{ margin: "auto" }}
            ></img>
          </Flex>

          <Box
            m={5}
            as="a"
            rel="noopener noreferrer"
            title="Compas zOOm n°23"
            href="http://www.lecompas.fr/doc/CompaszOOm23-18Mars2020.pdf"
            target="_blank"
          >
            <Text textAlign="center" mb={2}>
              Cliquez et Consultez
              <br /> le{" "}
              <span style={{ fontWeight: "600" }}>numéro spécial</span> Compas
              zOOm consacré à l'isolement des personnes face au Covid-19
              <Icon name="download"></Icon>
            </Text>

            <img
              src={startImg}
              alt="logo Compas Zoom"
              style={{ margin: "auto" }}
            />
          </Box>
        </Flex>
      </Flex>
    </>
  )
}

import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Stack,
} from "@chakra-ui/core"

export default () => (
  <Accordion allowMultiple>
    <AccordionItem>
      <AccordionHeader>
        <Box flex="1" textAlign="left">
          Vivre dans un logement ancien de 4 pièces ou plus
        </Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel pb={4} fontSize="sm">
        <Stack spacing={5}>
          <Text>
            Ce critère permet de mettre en évidence les conditions de vie des
            personnes âgées au sein de ces grands logements anciens (construits
            avant 1990 de 4 pièces ou plus), présentant plus de contraintes
            d'isolation, de coûts d'entretien (chauffage, électricité...) et de
            vétusté.
          </Text>
        </Stack>
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <AccordionHeader>
        <Box flex="1" textAlign="left">
          Vivre dans un logement collectif sans ascenseur
        </Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel pb={4} fontSize="sm">
        <Text>
          <Text as="strong">Vivre dans un logement sans ascenseur</Text>{" "}
          implique une mobilité plus difficile pour les personnes âgées qui peut
          créer un isolement et des fragilités importantes.
        </Text>
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <AccordionHeader>
        <Box flex="1" textAlign="left">
          Vivre dans un logement social, un logement précaire ou locataires dans
          collectif privé ancien
        </Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel pb={4} fontSize="sm">
        <Text>
          <Text as="strong">
            Vivre dans un logement social, dans un logement précaire ou être
            locataire dans collectif privé ancien
          </Text>{" "}
          sous entend a priori une fragilité monétaire plus grande que pour les
          autres ménages.
        </Text>
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <AccordionHeader>
        <Box flex="1" textAlign="left">
          Ne pas avoir de voiture
        </Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel pb={4} fontSize="sm">
        <Text>
          <Text as="strong">
            Vivre dans un ménage non équipé d'une voiture{" "}
          </Text>
          renforce le risque d'isolement et la maque d'autonomie pour les
          personnes loin des services de transport.
        </Text>
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <AccordionHeader>
        <Box flex="1" textAlign="left">
          Un bas niveau de formation
        </Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel pb={4} fontSize="sm">
        <Text>
          <Text as="strong">
            La personne référente du ménage (PRM) possède un bas niveau de
            formation :
          </Text>
          permet de mettre en avant les questions d'accès au droit et aux
          difficultés liées à l'usage du numérique par exemple ou aux démarches
          administratives.
        </Text>
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <AccordionHeader>
        <Box flex="1" textAlign="left">
          Immigration
        </Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel pb={4} fontSize="sm">
        <Text>
          <Text as="strong">
            La personne référente du ménage (PRM) est immigrée :
          </Text>
          peut révéler des difficultés plus importantes sur les questions de
          difficultés de lecture ou écriture de la langue française.
        </Text>
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
)
